import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavBar.css';  // Make sure you have a separate CSS file for styling

const NavBar: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li>
          <Link 
            to="/" 
            className={location.pathname === '/' ? 'active' : ''}
          >
            Home
          </Link>
        </li>
        <li>
          <Link 
            to="/services" 
            className={location.pathname === '/services' ? 'active' : ''}
          >
            Our Services
          </Link>
        </li>
        <li>
          <Link 
            to="/contact" 
            className={location.pathname === '/contact' ? 'active' : ''}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;