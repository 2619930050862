import React from 'react';
import './Home.css';  // Create a CSS file for Home page styling
import missionImage from '../assets/mission_image.jpg';  // Image for the mission statement
import expertiseImage from '../assets/our_expertise.jpg'; // Image for the expertise section
import logo from '../assets/MaynitLogo.jpg';  // Replace with your logo image path

const Home: React.FC = () => {
  return (
    <div className="home-container">
      {/* Logo Section */}
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo" />
      </div>

      {/* Mission Section */}
      <section className="mission-section">
        <div className="mission-image-container">
          <img src={missionImage} alt="Mission" className="mission-image" />
        </div>
        <div className="mission-text">
          <h2>Our Mission</h2>
          <div className="text-background">
            <p>
              At Maynit, we are passionate about empowering organizations to reach their fullest potential through the transformative power of the ServiceNow platform. Our commitment to excellence in consulting, implementation, and support enables our clients to streamline operations, enhance efficiency, and successfully navigate their digital transformation journeys.
            </p>
            <p>
              We understand that every organization faces unique challenges. Through deep expertise and a collaborative approach, we partner with our clients to design tailored solutions that address their immediate needs while laying the groundwork for long-term success in an ever-evolving digital landscape.
            </p>
            <p>
              Our mission is built on a foundation of integrity, transparency, and client satisfaction. We prioritize building lasting relationships based on trust and mutual respect, ensuring that we deliver measurable business value and exceed expectations with every engagement.
            </p>
          </div>
        </div>
      </section>

      {/* Our Expertise Section */}
      <section className="expertise-section">
        <div className="expertise-image-container">
          <img src={expertiseImage} alt="Expertise" className="expertise-image" />
        </div>
        <div className="expertise-text">
          <h2>Our Expertise</h2>
          <div className="text-background">
            <p>
              At Maynit, we specialize in providing comprehensive consulting and implementation services tailored to the ServiceNow platform. Our experienced team empowers organizations to optimize their Governance, Risk, and Compliance (GRC) processes and streamline IT Operations Management (ITOM) workflows. With our profound understanding of ServiceNow’s capabilities, we help clients harness the full potential of the platform to drive operational excellence and achieve their business goals.
            </p>
            <p>
              Our end-to-end solutions cover a wide range of services, including:
            </p>
            <ul>
              <li>IT Service Management (ITSM)</li>
              <li>IT Operations Management (ITOM)</li>
              <li>IT Business Management (ITBM)</li>
              <li>Security Operations</li>
              <li>Customer Service Management (CSM)</li>
              <li>Human Resources Service Delivery (HRSD)</li>
              <li>Governance, Risk, and Compliance (GRC)</li>
              <li>And much more.</li>
            </ul>
            <p>
              Our industry-specific knowledge allows us to effectively address the unique challenges faced by different sectors, ensuring that we deliver impactful solutions that foster growth and success.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
