import React, { useState } from 'react';
import './Contact.css';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you can implement the logic for sending the message
    setSubmitted(true);
    // Reset form fields after submission
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-container">
      <h1>Contact Information</h1>

      {/* {submitted ? (
        <div className="submission-message">
          <h2>Thank you for contacting us!</h2>
          <p>We appreciate your message and will get back to you soon.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Your Email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Your Message"
            />
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      )} */}

      <div className="contact-info">
        {/* <h2>Contact Information</h2> */}
        <p><strong>Phone:</strong> +91 73558 20907, +91 870-8717010</p>
        <p><strong>Email:</strong> <a href="mailto:contactus@maynitcorporation.com">contactus@maynitcorporation.com</a></p>
        <p><strong>Website:</strong> <a href="http://www.maynitcorporation.com" target="_blank" rel="noopener noreferrer">www.maynitcorporation.com</a></p>
        <p><strong>Address:</strong> Princeton, New Jersey, USA - 08536</p>
      </div>
    </div>
  );
};

export default Contact;
