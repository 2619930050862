import React, { useState } from 'react';
import './Services.css';

const ServiceItem: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="service-item">
      <h2 onClick={toggleSection} className="service-title">
        {title} <span>{isOpen ? '−' : '+'}</span>
      </h2>
      {isOpen && <p className="service-description">{description}</p>}
    </div>
  );
};

const Services: React.FC = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <ServiceItem
        title="Executive Search"
        description="We specialize in identifying and recruiting top-level executives across various industries. Our executive search process is meticulous, ensuring a precise match between candidate expertise and client requirements."
      />
      <ServiceItem
        title="Full-Time Resourcing"
        description="Maynit provides comprehensive permanent placement services for mid to senior-level positions. We understand the importance of long-term employee fit and aim to find candidates who align with the culture and goals of our client organizations."
      />
      <ServiceItem
        title="Contract Staffing"
        description="Our flexible staffing solutions help clients meet short-term project needs or seasonal demands. Whether it's administrative support, IT specialists, or other specialized roles, we provide skilled professionals on a contract basis."
      />
      <ServiceItem
        title="Consulting Services"
        description="Offering strategic advice and market insights to support talent management and workforce planning."
      />
      <ServiceItem
        title="Industry Specialization"
        description="Our team of experienced recruiters is well-versed in a variety of industries, including Information Technology, Life Science, BFSI, Automotive & Engineering. This specialization allows us to better understand the unique challenges and opportunities within each sector, ensuring successful placements."
      />
      <ServiceItem
        title="Geographic Reach"
        description="We are headquartered in New Jersey, USA, and we serve clients and candidates across North America, APAC, and EMEA. Through a network of industry experts and recruiters, we have the capability to support global recruitment needs."
      />
    </div>
  );
};

export default Services;
